import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    '& .testing img': {
      height: 70,
      paddingRight: 20
    },
    '& .logo-icon': {
      width: 130,
      height: 'auto',
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    },
    '& .logo-icon-small': {
      display: 'none'
    },
    '& .react-badge, & .logo-text': {
      transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  reactBadge: {
    backgroundColor: '#121212',
    color: '#61DAFB'
  }
}));

function Logo() {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, 'flex items-center')}>
      <Link to="/closing-board/calendar">
        {/* <img className="logo-icon" src="assets/images/logos/logo-horizontal-white.png" alt="logo" /> */}
        <img className="logo-icon-small" src="assets/images/logos/logo-image.png" alt="logo" />
      </Link>
    </div>
  );
}

export default Logo;
